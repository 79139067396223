import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../../components/seo"
import SocialLinks from "../../components/SocialLinks"
import GlobalStyles from "../../styles/global"
import * as S from "./HomeStyles"

const Home = () => {
  const {
    site: {
      siteMetadata: { position },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          position
        }
      }
    }
  `)

  return (
    <S.Base>
      <GlobalStyles />
      <SEO title="Home" />
      <S.Box>
        <S.Title>
          Jef
          <br />
          fer
          <br />
          son
          <br />
        </S.Title>
        <S.Position>{position}</S.Position>
        <SocialLinks />
      </S.Box>
    </S.Base>
  )
}

export default Home
