import styled from "styled-components"

export const Base = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(30deg, #d53369 0%, #1e1e34 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Box = styled.div`
  margin: 0 auto;
  padding: 30px;
  min-width: 350px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const Title = styled.span`
  font-family: "Verdana", sans-serif;
  font-size: 90px;
  font-weight: bold;
  background: linear-gradient(30deg, #d53369 0%, #1e1e34 150%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 350px;
  text-transform: uppercase;
  line-height: 0.77;
`

export const Position = styled.span`
  font-family: "Verdana", sans-serif;
  margin-top: 8px;
  font-size: 1.1em;
  max-width: 350px;
  text-transform: uppercase;
  color: #24243e;
`
